<template>
    <div>
        <el-tabs :animated="false" style="background:#fff; " v-model="activeTab">
            <el-tab-pane name="first" > 
                <span slot="label" style="padding-left:6px"><i class="iconfont  icon-envelope"></i> 消息留言</span>
                <div style="line-height:30px;height:35px;border-bottom:2px solid #389;font-size:12px">  
                    <span style=" margin-right:5px;font-size:12px;padding-left:6px">查询</span>
                    <el-select v-model="selparam" size="small"    style="width:140px; " >
                        <span v-for="item in tabCol" :key='item.key'>
                            <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                        </span>
                    </el-select>
                    <el-input  clearable :placeholder="input_hold" v-model="txtparam" @keyup.enter.native="getNoteList" size="small" style="width:350px; height:30px;">
                        <el-select v-model="oper" slot="prepend"  style="width:100px; " >
                            <span v-for="item in operArr" :key='item.IDSEQ'>
                                <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                            </span>
                        </el-select>
                        <el-button @click="getNoteList" slot="append" icon="el-icon-search"></el-button>
                    </el-input> 
                </div>
                <div>
                <el-table  :data="tabData" 
                    border                            
                    size="small"
                    :height="tabHeight" 
                    :row-key="getRowKey"
                    ref="refnote"
                    highlight-current-row
                    style="overflow:auto;margin-top:1px;"> 
                    <el-table-column type="index" width="70" label="序号"></el-table-column>
                    <el-table-column  v-for="(item ) in tabCol.filter(item=>item.thide=='N')" :key="item.key"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.edcss!=='checkbox'"
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox v-if="item.edcss=='checkbox'" disabled false-label="N" true-label="Y" v-model="scope.row[item.key]" >&nbsp;</el-checkbox>
                            <span v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="状态操作"
                        align="center"
                        width="135">
                        <template slot-scope="scope">
                            <el-button size="mini"  type="text" icon="iconfont icon-bianji" @click="editRec(scope.row,scope.$index,'NOTE')">回复</el-button>
                            <el-button size="mini"  type="text" icon="iconfont icon-shanchu1" @click="delRec(scope.row,scope.$index,'NOTE')">删除</el-button>
                        </template>
                    </el-table-column>  
                </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane name="second"> 
                <span slot="label"><i class="iconfont  icon-chanchengpinku"></i> 产品留言</span>
                <div style="line-height:30px;height:35px;border-bottom:2px solid #389;font-size:12px">  
                    <span style=" margin-right:5px;font-size:12px;padding-left:6px">查询</span>
                    <el-select v-model="selparam2" size="small"    style="width:140px; " >
                        <span v-for="item in prdCol" :key='item.key'>
                            <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                        </span>
                    </el-select>
                    <el-input  clearable :placeholder="input_hold" v-model="txtparam2" @keyup.enter.native="getPrdList" size="small" style="width:350px; height:30px;">
                        <el-select v-model="oper2" slot="prepend"  style="width:100px; " >
                            <span v-for="item in operArr" :key='item.IDSEQ'>
                                <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                            </span>
                        </el-select>
                        <el-button @click="getPrdList" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div>
                <el-table  :data="prdData" 
                    border                            
                    size="small"
                    :height="tabHeight" 
                    :row-key="getRowKey"
                    ref="refprd"
                    highlight-current-row
                    style="overflow:auto;margin-top:1px;"> 
                    <el-table-column type="index" width="70" label="序号"></el-table-column>
                    <el-table-column  v-for="(item ) in prdCol.filter(item=>item.thide=='N')" :key="item.key"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.edcss!=='checkbox'"
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox v-if="item.edcss=='checkbox'" disabled false-label="N" true-label="Y" v-model="scope.row[item.key]" >&nbsp;</el-checkbox>
                            <span v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="状态操作"
                        align="center"
                        width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" v-if="scope.row.ISCFM=='Y'"  type="text" icon="iconfont icon-fanshenhe" @click="stfgRec(scope.row,scope.$index,'N','PRD')">弃审</el-button> 
                            <el-button size="mini" v-if="scope.row.ISCFM!=='Y'"  type="text" icon="iconfont icon-shenhe" @click="stfgRec(scope.row,scope.$index,'Y','PRD')">审核</el-button> 
                            <el-button size="mini"  type="text" icon="iconfont icon-bianji" @click="editRec(scope.row,scope.$index,'PRD')">回复</el-button>
                            <el-button size="mini"  type="text" icon="iconfont icon-shanchu1" @click="delRec(scope.row,scope.$index,'PRD')">删除</el-button>
                        </template>
                    </el-table-column>  
                </el-table> 
                </div>
            </el-tab-pane>
            <el-tab-pane name="third"> 
                <span slot="label"><i class="iconfont  icon-jurassic_add-mulu"></i> 新闻留言</span>
                <div style="line-height:30px;height:35px;border-bottom:2px solid #389;font-size:12px">  
                    <span style=" margin-right:5px;font-size:12px;padding-left:6px">查询</span>
                    <el-select v-model="selparam3" size="small"    style="width:140px; " >
                        <span v-for="item in newCol" :key='item.key'>
                            <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                        </span>
                    </el-select>
                    <el-input  clearable :placeholder="input_hold" v-model="txtparam3" @keyup.enter.native="getNewList" size="small" style="width:350px; height:30px;">
                        <el-select v-model="oper3" slot="prepend"  style="width:100px; " >
                            <span v-for="item in operArr" :key='item.IDSEQ'>
                                <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                            </span>
                        </el-select>
                        <el-button @click="getNewList" slot="append" icon="el-icon-search"></el-button>
                    </el-input>   
                </div>
                <div>
                <el-table  :data="newData" 
                    border                            
                    size="small"
                    :height="tabHeight" 
                    :row-key="getRowKey"
                    ref="refnew"
                    highlight-current-row
                    style="overflow:auto;margin-top:1px;"> 
                    <el-table-column type="index" width="70" label="序号"></el-table-column>
                    <el-table-column  v-for="(item ) in newCol.filter(item=>item.thide=='N')" :key="item.key"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.edcss!=='checkbox'"
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox v-if="item.edcss=='checkbox'" disabled false-label="N" true-label="Y" v-model="scope.row[item.key]" >&nbsp;</el-checkbox>
                            <span v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="状态操作"
                        align="center"
                        width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" v-if="scope.row.ISCFM=='Y'"  type="text" icon="iconfont icon-fanshenhe" @click="stfgRec(scope.row,scope.$index,'N','NEW')">弃审</el-button> 
                            <el-button size="mini" v-if="scope.row.ISCFM!=='Y'"  type="text" icon="iconfont icon-shenhe" @click="stfgRec(scope.row,scope.$index,'Y','NEW')">审核</el-button> 
                            <el-button size="mini"  type="text" icon="iconfont icon-bianji" @click="editRec(scope.row,scope.$index,'NEW')">回复</el-button>
                            <el-button size="mini"  type="text" icon="iconfont icon-shanchu1" @click="delRec(scope.row,scope.$index,'NEW')">删除</el-button>
                        </template>
                    </el-table-column>  
                </el-table> 
                </div>
            </el-tab-pane>
            <el-tab-pane name="fourth"> 
                <span slot="label"><i class="iconfont  icon-pinpaizhuanxiang"></i> 博客留言</span>
                <div style="line-height:30px;height:35px;border-bottom:2px solid #389;font-size:12px">  
                    <span style=" margin-right:5px;font-size:12px;padding-left:6px">查询</span>
                    <el-select v-model="selparam4" size="small"    style="width:140px; " >
                        <span v-for="item in newCol" :key='item.key'>
                            <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                        </span>
                    </el-select>
                    <el-input  clearable :placeholder="input_hold" v-model="txtparam4" @keyup.enter.native="getBlogList" size="small" style="width:350px; height:30px;">
                        <el-select v-model="oper4" slot="prepend"  style="width:100px; " >
                            <span v-for="item in operArr" :key='item.IDSEQ'>
                                <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                            </span>
                        </el-select>
                        <el-button @click="getBlogList" slot="append" icon="el-icon-search"></el-button>
                    </el-input>   
                </div>
                <div>
                <el-table  :data="blogData" 
                    border                            
                    size="small"
                    :height="tabHeight" 
                    :row-key="getRowKey"
                    ref="refblog"
                    highlight-current-row
                    style="overflow:auto;margin-top:1px;"> 
                    <el-table-column type="index" width="70" label="序号"></el-table-column>
                    <el-table-column  v-for="(item ) in newCol.filter(item=>item.thide=='N')" :key="item.key"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.edcss!=='checkbox'"
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox v-if="item.edcss=='checkbox'" disabled false-label="N" true-label="Y" v-model="scope.row[item.key]" >&nbsp;</el-checkbox>
                            <span v-else v-text="scope.row[item.key]"></span>
                        </template>
                    </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="状态操作"
                        align="center"
                        width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" v-if="scope.row.ISCFM=='Y'"  type="text" icon="iconfont icon-fanshenhe" @click="stfgRec(scope.row,scope.$index,'N','BLOG')">弃审</el-button> 
                            <el-button size="mini" v-if="scope.row.ISCFM!=='Y'"  type="text" icon="iconfont icon-shenhe" @click="stfgRec(scope.row,scope.$index,'Y','BLOG')">审核</el-button> 
                            <el-button size="mini"  type="text" icon="iconfont icon-bianji" @click="editRec(scope.row,scope.$index,'BLOG')">回复</el-button>
                            <el-button size="mini"  type="text" icon="iconfont icon-shanchu1" @click="delRec(scope.row,scope.$index,'BLOG')">删除</el-button>
                        </template>
                    </el-table-column>  
                </el-table> 
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog class="pub_dialog" v-dialogDrag  :visible.sync="rmkWin" width="450" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">回复内容</span>
            </div> 
            <div style="line-height:30px"><span style="color:red;margin-right:4px">*</span>邮件标题：</div>
            <el-input v-model="mailttl" type="text"  placeholder="请输入邮件标题 "  size="medium"></el-input>
             
            <div style="line-height:30px"><span style="color:red;margin-right:4px">*</span>留言回复：</div>
            <el-input v-model="feedrmk" type="textarea" :rows="10" placeholder="请输入内容" maxlength="2000" show-word-limit></el-input>
            <div style="height:40px;line-height:40px;border-top:2px solid #ccc;padding-top:1px; " >
                <span style="float:right">
                    <el-button type="info" @click="rmkWin=false" size="small" style="height:35px;margin-right:30px">取消</el-button>
                    <el-button type="primary" @click="noteMail" size="small" style="height:35px; ">发送邮件</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { getTabColOrData,getBaseData } from '../../api/user'
    import {paramData,getDate} from '../../api/Select' 
    export default {
        name: "note_information",
        data () {
            return {
                promptWin:false,
                prompt:'',
                mailttl:'',
                activeTab:'first',
                input_hold:'请输入内容...',
                txtparam:'',
                selparam:'MAILADDR',
                oper:'like',
                txtparam2:'',
                selparam2:'MATNM',
                oper2:'like',
                txtparam3:'',
                selparam3:'',
                oper3:'like',
                txtparam4:'',
                selparam4:'',
                oper4:'like',
                v_url:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //api请求路径
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                tabCol:[],
                operArr:[],
                tabData:[],
                rmkWin:false,
                feedrmk :'',
                v_noteindex:'',
                v_noterow:'',
                prdCol:[],
                prdData:[],
                v_flag :'',
                newCol:[],
                newData:[],
                blogData:[],
            }
        },
        computed: {
            tabHeight: function() {
                return (window.innerHeight - 182) + 'px';
            }, 
        },
        mounted() {
            //操作符
            paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
                this.operArr =res.data.result
            })
            this.getTabField()
            this.getNoteList()
            this.getPrdList()
            this.getNewList()
            this.getBlogList()
        },
        methods: {
            //状态变更
            stfgRec(row,index,val,flag){
                let v_comm=''
                if (val==='Y'){
                    v_comm='审核'
                }else if (val==='N'){
                    v_comm='弃审'
                }
                this.$confirm('你确定要'+v_comm+'此记录吗，审核后前端留言将能显现','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'prd_note',p_cond:' set iscfm=\''+val+'\' '},
                    }).then(res=>{      
                        if(res.data.code =='200')  {  
                            if (flag=='PRD'){ 
                                this.prdData.splice(index,1,Object.assign(row,{ISCFM:val}))        
                            }else if (flag=='NEW'){ 
                                this.newData.splice(index,1,Object.assign(row,{ISCFM:val}))        
                            }else if (flag=='BLOG'){ 
                                this.blogData.splice(index,1,Object.assign(row,{ISCFM:val}))        
                            }
                        }
                    })
                }).catch(()=>{})
                
            },
            //留言信息
            getNoteList(){
                let vm =this ,cond_=''
                if (this.selparam){
                    if (this.oper==='like'){
                        cond_=cond_+' and upper('+this.selparam+') like upper(\'%'+this.txtparam+'%\')'
                    }else{
                        cond_=cond_+' and upper('+this.selparam+')'+this.oper+'upper(\''+this.txtparam+'\')'
                    }
                }
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_bs_note','','','','','','',cond_+' order by adddttm desc' ).then((res) => { 
                    this.tabData =res.data 
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        if (this.$refs.refnote.doLayout){
                            this.$refs.refnote.doLayout();
                        }
                    }) 
                })
            },
            //新闻信息
            getNewList(){
                let vm =this ,cond_=''
                if (this.selparam3){
                    if (this.oper3==='like'){
                        cond_=cond_+' and upper('+this.selparam3+') like upper(\'%'+this.txtparam3+'%\')'
                    }else{
                        cond_=cond_+' and upper('+this.selparam3+')'+this.oper3+'upper(\''+this.txtparam3+'\')'
                    }
                }
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_prd_news','idty','NEWS','','','','',cond_+' order by adddttm desc' ).then((res) => { 
                    this.newData =res.data 
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        if (this.$refs.refnew.doLayout){
                            this.$refs.refnew.doLayout();
                        }
                    }) 
                })
            },
            getBlogList(){
                let vm =this ,cond_=''
                if (this.selparam4){
                    if (this.oper4==='like'){
                        cond_=cond_+' and upper('+this.selparam4+') like upper(\'%'+this.txtparam4+'%\')'
                    }else{
                        cond_=cond_+' and upper('+this.selparam4+')'+this.oper4+'upper(\''+this.txtparam4+'\')'
                    }
                }
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_prd_news','idty','BLOG','','','','',cond_+' order by adddttm desc' ).then((res) => { 
                    this.blogData =res.data 
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        if (this.$refs.refblog.doLayout){
                            this.$refs.refblog.doLayout();
                        }
                    }) 
                })
            },
            //产品留言信息
            getPrdList(){
                let vm =this ,cond_=''
                if (this.selparam2){
                    if (this.oper2==='like'){
                        cond_=cond_+' and upper('+this.selparam2+') like upper(\'%'+this.txtparam2+'%\')'
                    }else{
                        cond_=cond_+' and upper('+this.selparam2+')'+this.oper2+'upper(\''+this.txtparam2+'\')'
                    }
                }
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'V_PRD_MAIL','','','','','','',cond_+' order by adddttm desc' ).then((res) => { 
                    this.prdData =res.data 
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        if (this.$refs.refprd.doLayout){
                            this.$refs.refprd.doLayout();
                        }
                    }) 
                })
            },
            editRec(row,index,flag){
                this.rmkWin =true
                this.feedrmk =row['FEEDRMK']
                this.v_noteindex =index
                this.v_noterow=row
                this.v_flag =flag
            },
            //留言回复邮件
            noteMail(){
                if (!this.mailttl){
                    this.promptWin=true
                    this.prompt='邮件标题不能为空'
                }else if (!this.feedrmk){
                    this.promptWin=true
                    this.prompt='回复内容不能为空'
                } else {
                    this.$confirm('你确定要发送回复邮件吗?','提示',{
                        confirmButtonText: '确定',
                        cancelButtonText:'取消',
                        customClass:'messageBox',
                        showClose:false,
                        type:'info',
                    }).then(()=>{
                        this.$axios({
                            method: 'post',
                            url:this.$store.state.baseUrl+'user/sendReqMail?username='+this.v_username+'&password='+this.v_password,
                            data: {'mailaddr':this.v_noterow['MAILADDR'],'title': this.mailttl, p_note:this.feedrmk},
                        }).then(res=>{
                            if (res.data.code!=='200'){
                                this.promptWin=true
                                this.prompt=res.data.result
                            }else{
                                let v_dttm=getDate('full')
                                let v_table =this.v_flag=='NOTE'?'bs_note':'prd_note'
                                this.$axios({
                                    method: 'post',
                                    url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                                    data: {p_key:'IDseq',p_value:this.v_noterow['IDSEQ'],p_table:v_table,p_cond:' set isok=\'Y\',upddttm=\''+v_dttm+'\',feedrmk=\''+this.feedrmk+'\''},
                                }).then(res=>{ 
                                    if (this.v_flag =='NOTE'){
                                        this.tabData.splice(this.v_noteindex,1,Object.assign(this.v_noterow,{ISOK:'Y',UPDDTTM:v_dttm,FEEDRMK:this.feedrmk}))
                                    }else if (this.v_flag =='PRD'){
                                        this.prdData.splice(this.v_noteindex,1,Object.assign(this.v_noterow,{ISOK:'Y',UPDDTTM:v_dttm,FEEDRMK:this.feedrmk}))
                                    }else if (this.v_flag =='NEW'){
                                        this.newData.splice(this.v_noteindex,1,Object.assign(this.v_noterow,{ISOK:'Y',UPDDTTM:v_dttm,FEEDRMK:this.feedrmk}))
                                    }else if (this.v_flag =='BLOG'){
                                        this.blogData.splice(this.v_noteindex,1,Object.assign(this.v_noterow,{ISOK:'Y',UPDDTTM:v_dttm,FEEDRMK:this.feedrmk}))
                                    }
                                })
                                this.rmkWin =false
                            }
                        })
                    }).catch(()=>{})
                }
            },
            //删除 
            delRec(row,index,flag){ 
                this.$confirm('你确定要删除此行记录吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    var vm=this;
                    let v_table =flag=='NOTE'?'bs_note':'prd_note'
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:row.IDSEQ,  p_table:v_table},
                    }).then(res=>{      
                        if(res.data.code =='200')  { 
                            if (flag=='NOTE'){ 
                                this.tabData.splice(index,1)         
                            }else if (flag=='PRD'){ 
                                this.prdData.splice(index,1)         
                            }else if (flag=='NEW'){ 
                                this.newData.splice(index,1)         
                            }else if (flag=='BLOG'){ 
                                this.blogData.splice(index,1)         
                            }
                        }     
                    })  
                }).catch(()=>{})  
            },
            getRowKey(row){
                return row.IDSEQ
            },
            //获取表列头字段
            getTabField(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','57427','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                    for (let k=0; k<res.data.length; k++){
                        vm.tabCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,
                            thide: res.data[k].THIDE,
                            edcss:res.data[k].EDCSS,
                        })
                    }            
                }) 
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','57427','fty','HEAD2','','',' order by sortby,lstseq').then((res) => {
                    for (let k=0; k<res.data.length; k++){
                        vm.prdCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,
                            thide: res.data[k].THIDE,
                            edcss:res.data[k].EDCSS,
                        })
                    }            
                })  
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','57427','fty','HEAD3','','',' order by sortby,lstseq').then((res) => {
                    for (let k=0; k<res.data.length; k++){
                        vm.newCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,
                            thide: res.data[k].THIDE,
                            edcss:res.data[k].EDCSS,
                        })
                    }            
                })   
            },
        }
    }
</script>

<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
 
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
</style>
